import { VerificationDocType } from '@/lib/verification-service';

import { ScreenNameType, User } from '../types';

export enum screenNameMap {
  '/signup' = 'SIGNUP',
  '/signup/passcode/confirm' = 'CONFIRM_PASSCODE',
  '/signup/passcode/confirmed' = 'CONFIRMED_PASSCODE',
  '/signup/passcode/create' = 'CREATE_PASSCODE',
  '/signup/phone-verification' = 'PHONE_VERIFICATION',
  '/signup/quota-reached' = 'QUOTA_REACHED',
  '/signup/waitlist' = 'WAITLIST',
  '/signup/waitlist/done' = 'WAITLIST_DONE',
  '/pre-join' = 'PRE_JOIN',
  '/signup/download-app' = 'PRE_JOIN_DOWNLOAD_APP',
  '/marketing/download-app' = 'MARKETING_DOWNLOAD_APP',
  '/marketing/waitlist' = 'MARKETING_WAITLIST',
  '/marketing/waitlist/done' = 'MARKETING_WAITLIST_DONE',
  '/goodbye' = 'GOODBYE',
  '/signin' = 'SIGNIN',
  '/signin/known-user' = 'SIGNIN_FOR_KNOWN_USER',
  '/signin/confirm-passcode' = 'CONFIRM_PASSCODE',
  '/signout' = 'SIGNOUT',
  '/dashboard' = 'HOME',
  '/dashboard/account-details' = 'ACCOUNT_DETAILS',
  '/join' = 'JOIN_BY_INVITE_CODE',
  '/join/:inviteCode' = 'JOIN_BY_INVITE_CODE',
  '/invite' = 'INVITE',
  '/recipients' = 'RECIPIENTS',
  '/recipients/v2' = 'RECIPIENTS',
  '/recipients/new' = 'ADD_RECIPIENTS',
  '/recipients/v2/add' = 'ADD_RECIPIENTS',
  '/recipients/v2/add/:currency' = 'ADD_RECIPIENTS_FORM',
  '/recipients/details/:id' = 'RECIPIENT_DETAILS',
  '/recipients/empty' = 'NO_RECIPIENT_FOUND',
  '/recipients/edit' = 'EDIT_RECIPIENTS',
  '/recipients/transactions/:id' = 'RECIPIENT_TRANSACTIONS',
  '/transactions' = 'TRANSACTIONS',
  '/transactions/new' = 'NEW_TRANSACTION',
  '/transactions/new/amount' = 'TRANSACTIONS_AMOUNT',
  '/transactions/new/recipient' = 'TRANSACTIONS_RECIPIENT',
  '/transactions/new/review' = 'TRANSACTIONS_REVIEW',
  '/transactions/new/payment_methods/:paymentMethod/instructions' = 'TRANSACTIONS_PAYMENT_METHOD_INSTRUCTIONS',
  '/transactions/new/payment-method' = 'TRANSACTIONS_PAYMENT_METHOD',
  '/transactions/new/processing' = 'TRANSACTION_PROCESSING',
  '/transactions/status' = 'TRANSACTIONS_STATUS',
  '/transactions/details/:id' = 'TRANSACTIONS_DETAILS',
  '/transactions/:id' = 'TRANSACTIONS_LIST',
  '/transactions/empty' = 'TRANSACTIONS_EMPTY',
  '/address-information' = 'ADDRESS_INFORMATION',
  '/address-information/review' = 'ADDRESS_INFORMATION_REVIEW',
  '/kyc/id-verification' = 'KYC_ID_VERIFICATION',
  '/kyc/liveliness' = 'KYC_LIVELINESS',
  '/kyc/success' = 'KYC_SUCCESS',
  '/personal-information' = 'PERSONAL_INFORMATION',
  '/accounts/:id/add' = 'TOPUP_ACCOUNT',
  '/accounts/:id/account-details' = 'ACCOUNT_DETAILS',
  '/add-money/success' = 'ADD_MONEY_SUCCESS',
  '/download-statement' = 'DOWNLOAD_STATEMENT',
  '/secondary-contact-verification/:contactType' = 'SECONDARY_CONTACT_VERIFICATION',
  '/settings' = 'SETTINGS',
  '/settings/profile' = 'PROFILE_SETTINGS',
  '/settings/profile/update-contact/phone' = 'UPDATE_PHONE_NUMBER',
  '/settings/profile/update-contact/email' = 'UPDATE_EMAIL',
  '/settings/transfer-limits' = 'TRANSFER_LIMITS_SETTINGS',
  '/settings/security' = 'SECURITY_SETTINGS',
  '/settings/notifications' = 'NOTIFICATION_SETTINGS',
  '/promo-code' = 'PROMO_CODE_FORM',
  '/promo-code/success' = 'PROMO_CODE_SUCCESS',
  '/support' = 'SUPPORT',
  '/recover-passcode' = 'RECOVER_PASSCODE',
  '/recover-passcode/create' = 'RECOVER_PASSCODE_CREATE',
  '/recover-passcode/confirm' = 'RECOVER_PASSCODE_CONFIRM',
  '/privacy-policy' = 'PRIVACY_POLICY',
  '/terms-of-use' = 'TERMS_OF_USE',
  '/2fa' = '2FA',
  '/2fa/face_match' = '2FA_FACE_MATCH',
  '/2fa/pass_code' = '2FA_PASSCODE',
  '/2fa/otp' = '2FA_OTP',
  '/personal-information/verify' = 'PERSONAL_INFORMATION_VERIFY',
  '/personal-information/email' = 'PERSONAL_INFORMATION_EMAIL',
  '/personal-information/mobile-number' = 'PERSONAL_INFORMATION_MOBILE_NUMBER',
  '/kyc/upgrade' = 'KYC_UPGRADE',
  '/kyc/upgrade/level-2' = 'KYC_UPGRADE_LEVEL_2',
  '/primary-contact-verification' = 'PRIMARY_CONTACT_VERIFICATION',
  '/stay-tuned' = 'STAY_TUNED',
  '/onboarding-complete' = 'ONBOARDING_COMPLETE',
  '/citizenship-information' = 'CITIZENSHIP_INFORMATION',
  '/contact-verification/email' = 'EMAIL_CONTACT_VERIFICATION',
  '/contact-verification/phone' = 'PHONE_CONTACT_VERIFICATION',
  '/edd/annual-income' = 'EDD_1_ANNUAL_INCOME',
  '/edd/source-of-funds' = 'EDD_1_SOURCE_OF_FUNDS',
  '/edd/employer-details' = 'EDD_2_EMPLOYER_DETAILS',
  '/edd/business-activity' = 'EDD_2_BUSINESS_ACTIVITY',
  '/edd/company-details' = 'EDD_2_COMPANY_DETAILS',
  '/edd/institution-details' = 'EDD_2_INSTITUTION_DETAILS',
  '/edd/pension-provider' = 'EDD_2_PENSION_PROVIDER',
  '/edd/prompt' = 'EDD_3_PROMPT',
  '/edd/upload-document' = 'EDD_3_UPLOAD_DOCUMENT',
  '/edd/on-hold' = 'EDD_3_ON_HOLD',
}

const matchPathname = (pattern: string, pathname: string): boolean => {
  const patternSegments = pattern?.split('/').filter(Boolean);
  const pathSegments = pathname?.split('/').filter(Boolean);
  if (patternSegments.length !== pathSegments?.length) return false;

  return patternSegments.every((segment, index) => {
    if (segment.startsWith(':')) return true;
    return segment === pathSegments[index];
  });
};

export const getScreenNameByPathname = (pathname: ScreenNameType) => {
  for (const pattern in screenNameMap) {
    if (matchPathname(pattern, pathname)) {
      return screenNameMap[pattern as ScreenNameType];
    }
  }
  return undefined;
};

export const getIdVerificationName = (
  verificationDocValue: VerificationDocType | 'DRIVERS_LICENSE',
) => {
  switch (verificationDocValue) {
    case VerificationDocType.RESIDENCE_PERMIT:
      return 'brp';
    case VerificationDocType.DRIVERS_LICENSE:
    case 'DRIVERS_LICENSE':
      return 'license';
    case VerificationDocType.PASSPORT:
      return 'passport';
    default:
      return '';
  }
};

export const mapSyncUserKeys = (data: Record<string, any>): Partial<User> => {
  return {
    email: data.email,
    phone_number: data.phoneInfo?.number,
    country: data.address.countryCode,
    username: data.username,
    city: data.address.city,
    $user_id: data.username,
  };
};
