import { FlagServiceProvider } from './flag-service-provider';
import { FlagServiceProviderName } from './flag-service-provider-name';
import { envFlagService } from './providers/env/env-flag-service-provider';
import { unleashFlagService } from './providers/unleash/unleash-flag-service-provider';

import { config } from '@/config';

/** @returns The active feature flag service provider */
export function getFlagServiceProvider(): FlagServiceProvider {
  if (
    config.FEATURE_FLAG_SERVICE_PROVIDER === FlagServiceProviderName.Unleash
  ) {
    return unleashFlagService;
  }

  return envFlagService;
}
