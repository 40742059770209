import { PropsWithChildren } from 'react';

import { toScreamingSnakeCase } from '@/lib/utils';

import { FlagName } from '../../flag-name';
import { FlagServiceProvider } from '../../flag-service-provider';
import { FlagServiceProviderName } from '../../flag-service-provider-name';
import { FlagStatus } from '../../flag-status';

import { config } from '@/config';

/** The environment feature flag service provider */
class EnvFlagServiceProvider implements FlagServiceProvider {
  name = FlagServiceProviderName.Env;

  /** Get the prefixed name of a feature flag */
  getFlagName(name: FlagName) {
    return toScreamingSnakeCase(name);
  }

  /** Get a feature flag on the server */
  async getFlag(name: FlagName) {
    const flagName = this.getFlagName(name);
    const flags = await this.getFlags();

    return flags.isEnabled(flagName);
  }

  /**
   * Get a set of feature flags on the server
   * @see {@link https://docs.getunleash.io/reference/sdks/next-js#a-app-router}
   */
  getFlags() {
    return Promise.resolve({
      isEnabled: (name: string) => {
        return Boolean(config[name as keyof typeof config]);
      },
    });
  }

  /** Use a feature flag on the client */
  useFlag = (name: FlagName) => {
    const flagName = this.getFlagName(name);
    return Boolean(config[flagName as keyof typeof config]);
  };

  /** Use the status of feature flags on the client */
  useFlagsStatus = () => {
    return FlagStatus.Ready;
  };

  /** The context provider of the service provider */
  ContextProvider = ({ children }: PropsWithChildren) => children;
}

export const envFlagService = new EnvFlagServiceProvider();
