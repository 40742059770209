import { logError } from '../utils';
import { FlagName } from './flag-name';
import { getFlagServiceProvider } from './get-flag-service-provider';

/**
 * Get a feature flag on the server.
 * @param name - The name of the feature flag
 * @see {@link https://docs.getunleash.io/reference/sdks/next-js#a-app-router}
 */
export async function getFlag(name: FlagName) {
  try {
    return await getFlagServiceProvider().getFlag(name);
  } catch (error) {
    logError('Error getting feature flag', error);

    return false;
  }
}
