'use client';

import { FlagName } from '@/lib/feature-flag-service/flag-name';
import { getFlag } from '@/lib/feature-flag-service/get-flag';
import { debugLog } from '@/lib/utils';

import {
  AnalyticsEvent,
  AnalyticsProviderType,
  DeviceInfo,
  User,
} from '../../types';

export class SingularProvider implements AnalyticsProviderType {
  user?: User;
  deviceInfo?: DeviceInfo;
  private isInitialized: boolean = false;
  private SingularConfig: any;
  private singularSdk: any;
  private isSingularEnabled = false;

  constructor() {
    this.initializeSingular();
  }

  private async getSingularEnabled() {
    this.isSingularEnabled = await getFlag(FlagName.EnableSingular);
  }

  private async initializeSingular() {
    await this.getSingularEnabled();
    if (!this.isInitialized && this.isSingularEnabled) {
      // WHY: as singular-sdk is throwing error
      // window is not defiend even we have defined client side library
      // so better to check before importing
      if (typeof window !== 'undefined') {
        // @ts-ignore
        import('@/lib/singular-sdk').then((module) => {
          this.SingularConfig = module.SingularConfig;
          this.singularSdk = module.singularSdk;

          const singularConfig = new this.SingularConfig();
          this.singularSdk.init(singularConfig);
          this.isInitialized = true;
        });
      }
    }
  }

  private async ensureInitialized() {
    if (!this.isInitialized && this.isSingularEnabled) {
      await this.initializeSingular();
    }
  }

  async identify(userId: string): Promise<void> {
    await this.ensureInitialized();
    this.singularSdk?.login(userId);
  }

  async sendEvent(event: AnalyticsEvent): Promise<void> {
    await this.ensureInitialized();
    const eventData = {
      ...this.user,
      ...this.deviceInfo,
      ...event.data,
      timestamp: Date.now(),
    };
    // API call to Singular
    debugLog('Singular', event.name, { data: eventData });
    this.singularSdk?.event(event.name, eventData);
  }

  syncUser(user: User) {
    this.user = {
      ...this.user,
      ...user,
    };
  }

  async reset(): Promise<void> {
    await this.ensureInitialized();
    await this.singularSdk?.logout();
    this.user = undefined;
  }

  async pageVisit(): Promise<void> {
    await this.ensureInitialized();
    this.singularSdk?.pageVisit();
  }
}

export const singularSDK = new SingularProvider();
