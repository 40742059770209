export enum FlagName {
  EnablePayment = 'enablePayment',
  EnableSingular = 'enableSingular',
  EnableTransactionFee = 'enableTransactionFee',
  // Send Money
  SendMoneyPaymentMethodsEnableFee = 'sendMoney.paymentMethods.enableFee',
  // Transaction
  TransactionEnableRepeat = 'transaction.enableRepeat',
  TransactionEnableShareReceipt = 'transaction.enableShareReceipt',
  // Transaction History
  TransactionHistoryEnableDownload = 'transactionHistory.enableDownload',
  // Enable Contact Update
  EnableContactUpdate = 'enableContactUpdate',
  // Enable upgrade role with promo code
  EnableUpgradeRoleWithPromoCode = 'enableUpgradeRoleWithPromoCode',
}
