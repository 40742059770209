import {
  useFlag as useUnleashFlag,
  useFlagsStatus as useUnleashFlagStatus,
} from '@unleash/nextjs/client';

import { FlagName } from '../../flag-name';
import { FlagServiceProvider } from '../../flag-service-provider';
import { FlagServiceProviderName } from '../../flag-service-provider-name';
import { FlagStatus } from '../../flag-status';
import { getUnleashFlags } from './get-unleash-flags';
import { UnleashFlagContextProvider } from './unleash-flag-context-provider';

/** The Unleash feature flag service provider */
class UnleashFlagServiceProvider implements FlagServiceProvider {
  name = FlagServiceProviderName.Unleash;

  /** Get the prefixed name of a feature flag */
  getFlagName(name: FlagName) {
    return `moniepoint.fx.web.${name}`;
  }

  /** Get a feature flag on the server */
  async getFlag(name: FlagName) {
    const flagName = this.getFlagName(name);
    const flags = await this.getFlags();

    return flags.isEnabled(flagName);
  }

  /** Get a set of feature flags on the server */
  getFlags() {
    return getUnleashFlags();
  }

  /** Use a feature flag on the client */
  useFlag = (name: FlagName) => {
    const flagName = this.getFlagName(name);
    return useUnleashFlag(flagName);
  };

  /** Use the status of feature flags on the client */
  useFlagsStatus = () => {
    const { flagsError, flagsReady } = useUnleashFlagStatus();

    if (flagsError) {
      return FlagStatus.Error;
    }

    if (!flagsReady) {
      return FlagStatus.Pending;
    }

    return FlagStatus.Ready;
  };

  /** The provider of the feature flag service */
  ContextProvider = UnleashFlagContextProvider;
}

export const unleashFlagService = new UnleashFlagServiceProvider();
